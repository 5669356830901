import React,{useState} from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images

import CarouselPage from "./CarouselPage";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  addNewUser as onAddNewUser,
} from "store/contacts/actions";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Register2 = (props) => {
  const [getIsActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  //meta title
  document.title="Kayıt Ol | MEGA - Kullanıcı Paneli";

  const [getUserInput, setUserInput] = useState([]);

  const sendMessage = async()=> {
let data = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"\r\nxmlns="https://webservice.asistiletisim.com.tr/SmsProxy">\r\n<soapenv:Header/>\r\n <soapenv:Body>\r\n <sendSms>\r\n <requestXml>\r\n <![CDATA[\r\n <SendSms>\r\n <Username>megaapi</Username> \r\n <Password>Mg/*-8520</Password>\r\n <UserCode>6817</UserCode>\r\n <AccountId>3887</AccountId>\r\n <Originator>MERAM BLD</Originator>\r\n <SendDate></SendDate>\r\n <ValidityPeriod>60</ValidityPeriod>\r\n <MessageText>test</MessageText>\r\n <IsCheckBlackList>0</IsCheckBlackList>\r\n <ReceiverList>\r\n <Receiver>905325776301</Receiver>\r\n </ReceiverList>\r\n </SendSms>\r\n ]]>\r\n </requestXml>\r\n </sendSms>\r\n </soapenv:Body>\r\n</soapenv:Envelope>';


await axios.post(`https://getjob.stechomeyazilim.info:5101/postMessageMega/send`, data).then(async(res) => {
    
showToast(true)

validation.resetForm();
})


  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


  //form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
      tcNumber:'',
      datenumber: '',
      phoneNumber: ''
    },
    validationSchema: Yup.object({
      tcNumber: Yup.string().min(11, "Tc Numaranız 11 Haneli Olmalıdır").max(11, "Tc Numaranız 11 Haneden Daha Uzun!").required("Lütfen TC Numaranzı Eksiksiz Giriniz"),
      datenumber: Yup.string().required("Lütfen Doğum Tarihinizi Giriniz"),
      password: Yup.string().required("Lütfen Şifrenizi Giriniz"),
      phoneNumber: Yup.string().min(11, "Telefon Numaranız 11 Hane Olmalıdır").max(11, "Telefon Numaranız 11 Haneden Daha Uzun!").required("Telefon Numaranızı Girmeniz Zorunludur"),

    }),
    onSubmit: async(values) => {
      var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;

      if(format.test(values.password)){
        showToast(false,"Lütfen şifrenizde özel karakter kullanmayınız!");

      } else {
        if(getIsActive == false){
          showToast(false,"Lütfen şartları kabul ediniz");
    
         }else{
    
                
      await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserLoginNumber/select/${values.phoneNumber}`).then(async(res) => {
      if(res.data.length > 0){
              showToast(false,"Önceden Sisteme Bu Numara İle Kayıt Olunmuştur!");
      }if(res.data.length == 0){
    checkAdress(values)
      }
    })
    
         
    
         }
      }


     
    }
  });



  function showToast(type,getFalseDesc) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Kayıt İşleminiz Gerçekleşmiştir, Giriş Yapabilirsiniz!"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);

    if(type == true){
    navigate('/login')
    }

  }
  
  const [getSuccess, setSuccess] = useState(false);

  const checkAdress = (values) => {

    setLoading(true)

    var firstDate = moment(values.datenumber).format('YYYY-MM-DD'); //Create date using string-format constructor
    var secondDate = moment(new Date());

    var duration = moment.duration(secondDate.diff(firstDate));
    var years = duration.asYears();
 
    console.log(Math.round(years))




let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://getjob.stechomeyazilim.info:5101/postMeram/send',
  headers: { 
    'Content-Type': 'application/json'
  },
};

axios.request(config)
.then(async(response) => {
let data = JSON.stringify({
  "action": "getCollocutorFromNvi",
  "params": {
    "identityNumber": values.tcNumber,
    "birthdate": moment(values.datenumber).format('YYYY-MM-DD')
  },
  "SP": {
    "currentCompanyId": 3429,
    "platform": "3rdParty",
    "language": "tr",
    "originatorMemberId": 764,
    "proxyMemberId": null,
    "sessionId": (response.data).data.sessionId
  }
});

let config2 = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://getjob.stechomeyazilim.info:5101/postMeram2/send',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};

axios.request(config2)
.then((response) => {

  console.log("sdfnmkdsşfşk",response.data.code)
  if(response.data.code == 0){
    
      


      var localDate = moment().local();

      const newUser = {
        NameSurname: response.data.data.collocutor.nameSurname,
         CreatedDateTime: localDate,
      //  District: values["tags"],
     //   Adress: values["projects"],
        Users_Type_ID: 9,
       // UsersPhoto: values["projects"],
        MailAdress: null,
        Users_TCNumber: values["tcNumber"],
        Users_Birthday: moment(values["datenumber"]).format('YYYY-MM-DD'),
        Users_IsActive: true,
        Users_Password: values["password"],
        GsmNumber :values["phoneNumber"],
        Adress : response.data.data.address.explicitAddress,
        Users_Gender: response.data.data.collocutor.genderDescription
      };

      dispatch(onAddNewUser(newUser));
      validation.resetForm();

      setLoading(false)

      setSuccess(true)
      showToast(true);

   
  }else{
    
    showToast(false,response.data.description);
    setLoading(false)

  }
})
.catch((error) => {
  console.log(error);
});


})
.catch((error) => {
  console.log("hata",error);
});

  }


  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            {loading && <div className="loading-screen">Yükleniyor...</div>}

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                   
                    <div className="">
                      <div>
                        <h5 className="text-primary">Kullanıcı Oluştur</h5>
                       
                      </div>

                      <div className="mt-4">

                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >

<div className="mb-3">
                            <Label className="form-label">T.C Kimlik Numaranız</Label>
                            <Input
                              name="tcNumber"
                              type="text"
                              placeholder="T.C Kimlik Numaranızı Giriniz"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.tcNumber || ""}
                              invalid={
                                validation.touched.tcNumber && validation.errors.tcNumber ? true : false
                              }
                            />
                            {validation.touched.tcNumber && validation.errors.tcNumber ? (
                              <FormFeedback type="invalid">{validation.errors.tcNumber}</FormFeedback>
                            ) : null}
                          </div>

                     

                          <div className="mb-3">
                      <Label className="control-label">DOĞUM TARİHİ (Ör:01.01.1990)</Label>
                          <Input
                        name="datenumber"
                        type="date"
                        placeholder="TC Numarası"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.datenumber || ""}
                        invalid={
                          validation.touched.datenumber && validation.errors.datenumber ? true : false
                        }
                      />
                      {validation.touched.datenumber && validation.errors.datenumber ? (
                        <FormFeedback type="invalid">{validation.errors.datenumber}</FormFeedback>
                      ) : null}
                        </div>

                        <div className="mb-3">
                        <Label className="control-label">Tel. Numaranız (*0 ekleyin)</Label>

                        <div className="mb-3">                      
                        <Input
                        name="phoneNumber"
                        className="form-control"
                        type="tel"
                        placeholder="Telefon Numarası"
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={
                          validation.touched.phoneNumber && validation.errors.phoneNumber ? true : false
                        }
                      />
                       {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                              <FormFeedback type="invalid">{validation.errors.phoneNumber}</FormFeedback>
                            ) : null}
                    </div>
                        
</div>
                      
        
                          <div className="mb-3">
                            <Label className="form-label">Şifreniz</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Şifrenizi Giriniz"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                          <Label className="form-label">*Şifrenizde özel karakter kullanmayınız!</Label>


                          <div className="d-flex">
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                            <p className="mb-0">
                            <a rel="noreferrer" target="_blank" href="https://ilkuniversitem.meram.bel.tr/megagizlilik.pdf" className="text-primary">
                               Kullanım {" "}
                              </a>
                            Şartları Kabul Ediyorum
                             
                            </p>

                          

                          </div>

                          <div className="mt-4 d-grid">
                            <button
                              className="btn btn-primary waves-effect waves-light "
                              type="submit"
                            >
                              KAYIT OL
                            </button>
                          </div>

                        </Form>


                     
                        <div className="mt-5 text-center">
                          <p>
                            Hesabınız Var Mı ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Giriş Yap
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                 
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register2;
