import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import { useLocation, useNavigate } from "react-router-dom";
import { Button,Input, Label} from "reactstrap"
import axios from "axios";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import toastr from "toastr";
import "toastr/build/toastr.min.css";


import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
var creditcardutils = require('creditcardutils');

function EcommerceOrder() {
  const navigate = useNavigate();
  //meta title
  document.title = "Başvuru Yap | Mega - Öğrenci Paneli";


  const [CardExpireDateMonth, setCardExpireDateMonth] = useState(null)
  const [CardExpireDateYear, setCardExpireDateYear] = useState(null)
  const [CardCVV2, setCardCVV2] = useState(null)

  const location = useLocation();

  const state = location.state;


  const [cardNumber, setCardNumber] = React.useState("");
  const [cardExpires, setCardExpires] = React.useState("");
  const [getHolder, setHolder] = React.useState("");
  const [getCVC, setCVC] = React.useState(null);
  const [getFocus, setFocus] = React.useState(null);
  const [getCardType, setCardType] = React.useState(null);
  const [getPrice, setPrice] = React.useState(null);

  function cc_format(value) {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 16);
    const parts = [];
  
    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }
  
    return parts.length > 1 ? parts.join(" ") : value;
  }


  function formatExp(value) {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 16);
    const parts = [];
  
    for (let i = 0; i < v.length; i += 2) {
      parts.push(v.substr(i, 2));
    }
  
    return parts.length > 1 ? parts.join("/") : value;
  }

  function showToast(type,desc) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Başvurunuzu Tamamladınız!"
    } else{
       ele = "error"
       message = desc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);

    if(type == true){
   /* navigate('/myapplication', { state: {
      update: true
      } })*/
    }

  }



  useEffect(() => {
    const handlePopState = (event) => {
      // Belirli bir duruma göre geri gitmeyi engelleme
        window.history.pushState(null, null, window.location.pathname);
     
    };
  
    window.addEventListener('popstate', handlePopState);
  
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);


/*  useEffect(() => {
    const _getData = async(values) => {
      try {
      await axios.get(`https://getjob.stechomeyazilim.info:5101/getCheckMega/select`).then(async(res) => {
       
      setResData(res.data)
      console.log("kldfklş",res.data)
    
    })
    } catch (error) {
     
    }
    }

    _getData()

  }, []); */





  //delete order
  const [getPageNumber, setPageNumber] = useState(1);

  const _setPayment = async()=> {
     setPageNumber(2)
  }

const sendMessage = async(name,day,starttime,finishtime)=> {

  let userDetail = localStorage.getItem("authUser");

  let detailUser = JSON.parse(userDetail)

  let newData = {"GSM" : '9' + detailUser.GsmNumber, 
  "Message" :  `Degerli ogrencimiz ${detailUser.NameSurname} Meram Gelisim Akademisine Hos Geldin! Yeni Nesil Egitim kapsaminda ödemeniz gerçekleşmiştir.https://maps.app.goo.gl/pJWN2HeRrpTT6ZFWA`}

  
  
  await axios.post(`https://getjob.stechomeyazilim.info:5101/sendSmsMega/send`, newData).then(async(res) => {
      
  })
  
  
    }

    
    const  handleInputChange = (e) => {
      const { name, value } = e.target;
      

      if(name == "number"){

          if(value.length < 20){
              let cardType = creditcardutils.parseCardType(value);

              setCardType(cardType)
              setCardNumber(value)
          }
        
      }
      if(name == "name"){
          setHolder(value)

      }
      if(name == "expiry"){
          if(value.length < 6){

            setCardExpireDateMonth(value.slice(0, 2))
            setCardExpireDateYear(value.slice(3, 5))

          setCardExpires(value)
          }
      }

      if(name == "cvc"){
          if(value.length < 4){
          setCVC(value)
          }
      }
    }

    React.useEffect(() => {
    
      const handleMessage = (event) => {
        if (event.data === 'success') {
          console.log('İşlem başarıyla tamamlandı');
  
          postData()
          // İşlem başarıyla tamamlandığında yapılacak işlemleri burada gerçekleştirin
        }
      }
    
      window.addEventListener('message', handleMessage);
    
      return () => {
        window.removeEventListener('message', handleMessage);
      };
   
    }, []);


    
    const postData = async() => {
      try {

      var localDate = moment().local();

      let userDetail = localStorage.getItem("authUser");
  
      const newOrder = {
        Teacher_Workshop_ID:null,
        Created_DateTime: localDate,
        Users_ID: JSON.parse(userDetail).ID,
        IsActive:false,
        Teacher_Session_ID:null,
        }
        // save new order
      //  dispatch(onAddApply(newOrder));

     /* await axios.post(`https://getjob.stechomeyazilim.info:5101/addApply/send`, newOrder).then(async(res) => {
      
      

      }) */

      const newOrder2 = {
        Created_DateTime: localDate,
        Users_ID: JSON.parse(userDetail).ID,
        Classes_ID:null,
        }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addUsersSuccessPayment/insert`, newOrder2).then(async(res) => {
    

        sendMessage("Manuel Ödeme")
  
  
        showToast(true)


    })



      } catch (error) {
       
        console.log("error123",error)
      }

    

    }

    const handleInputFocus = (e) => {
      setFocus(e.target.name)
    }

    const formattedPrice = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY'
    }).format(getPrice);

    return(
      <React.Fragment>

      <div className="page-content justify-center items-center">
            <div className="container-fluid">
              <Breadcrumbs title="Ödeme" breadcrumbItem="Ödeme" />
             
             {getPageNumber == 1 ?
             <div className="justify-center items-center">


   
        <Cards
        locale={{valid: 'Geçerlilik Tarihi'}}
        placeholders={{ name: 'Kart Sahibi', expiry: 'Geçerlilik Tarihi',number: 'Kart Numarası' }}
          cvc={getCVC}
          issuer={getCardType}
          expiry={cardExpires}
          focused={getFocus}
          name={getHolder}
          number={cardNumber}
          preview={true}
        />
        <form>
      
        <div className="mb-3 justify-center items-center">
                          <h5 className="text-red">Toplam Ücret - {formattedPrice} ₺</h5>
          </div>

          <div className="d-flex">

        <div className="mb-3 m-2">
                          <Label htmlFor="metatitle">Ücret</Label>
        	<input
            type="number"
            name="price"
            className="form-control"
                        placeholder="Toplam Ücret"
           
            value={getPrice}
            onChange={(val)=> setPrice(val.target.value)}

          />
          </div>
          </div>

      <div className="d-flex">
        <div className="mb-3 m-2">
                          <Label htmlFor="metatitle">Kart Numarası</Label>
        	<input
            type="tel"
            name="number"
            className="form-control"
                        placeholder="Kart Numarası"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            value={cc_format(cardNumber)}

          />
          </div>
          <div className="mb-3 m-2">
                          <Label htmlFor="metatitle">Kart Sahibi</Label>
         <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Kart Sahibi"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          </div>
          </div>


         <div className="d-flex ml-3">
         

          <div className="mb-3 m-2">
                          <Label htmlFor="metatitle">Geçerlilik Tarihi</Label>
<input
            type="text"
            name="expiry"
            className="form-control"
            placeholder="Geçerlilik Tarihi"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            value={formatExp(cardExpires)}

          />
          </div>
         

          <div className="mb-3 m-2">
                          <Label htmlFor="metatitle">Güvenlik Kodu</Label>
<input
            type="text"
            name="cvc"
            className="form-control"
            placeholder="Güvenlik Kodu"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          </div>
          </div>

        </form>

          

                  

                        <div className="d-flex flex-wrap gap-2 m-2">
                      <Button onClick={()=> _setPayment()} type="submit" color="primary" className="btn ">
                        Ödeme Yap
                      </Button>
                     
                    </div>
                    </div>

                    :
              <div className="grid-containerNew">

     
      <div className="modal-content">
    
        <iframe  target="_parent" src={`https://megaadmin.stechomeyazilim.info/deneme/2_Odeme.php?Name=berk&CardNumber=${cardNumber.replace(/\s+/g, '')}&CardExpireDateMonth=${CardExpireDateMonth}&Name=${getHolder}&CardExpireDateYear=${CardExpireDateYear}&CardCVV2=${getCVC}&Tutar=${getPrice * 100}`} height={500} width={500}/>         
      </div>

        </div> }
      </div>
      </div>


    
    </React.Fragment>
  )
}

EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;