import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import {
  deleteOrder as onDeleteOrder,
  addApply as onAddApply,

} from "store/actions";

import {
  addWorkshopSubdomain as onAddWorkshopSubdomain,
  getActiveWorkShop as onGetActiveWorkShop,
  updateSubdomain as onUpdateWorkshop,
} from "store/contacts/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader,  Button, Card, CardBody, Col,} from "reactstrap"
import { map } from "lodash";
import axios from "axios";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function EcommerceOrder() {
  const navigate = useNavigate();
  //meta title
  document.title = "Başvuru Yap | Mega - Öğrenci Paneli";

  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [orderList, setOrderList] = useState([]);

  const location = useLocation();

  const state = location.state;


  function showToast(type,desc) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Başvurunuzu Tamamladınız!"
    } else{
       ele = "error"
       message = desc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "MEGA";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);

    if(type == true){
    navigate('/myapplication', { state: {
      update: true
      } })
    }

  }

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.workshopApply,
  }));


  
  useEffect(() => {



    if (state.update == true) {
      let userDetail = localStorage.getItem("authUser");
      dispatch(onGetActiveWorkShop(JSON.parse(userDetail).ID));
      setIsEdit(false);
    } else{
      let userDetail = localStorage.getItem("authUser");
      dispatch(onGetActiveWorkShop(JSON.parse(userDetail).ID));
      setIsEdit(false);


  }
    
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);


  const [getResData, setResData] = useState([]);


/*  useEffect(() => {
    const _getData = async(values) => {
      try {
      await axios.get(`https://getjob.stechomeyazilim.info:5101/getCheckMega/select`).then(async(res) => {
       
      setResData(res.data)
      console.log("kldfklş",res.data)
    
    })
    } catch (error) {
     
    }
    }

    _getData()

  }, []); */





  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [dateArray, setDateArray] = useState([]);
  const [getSeans, setSeans] = useState(null);
  const [getTeacherherSession, setTeacherherSession] = useState(null);

  
  
  const _setDateModalFunc = async(item) => {

    setDateModal(false)

    var localDate = moment().local();

    let userDetail = localStorage.getItem("authUser");


   const newOrder = {
      Teacher_Workshop_ID:null,
      Created_DateTime: localDate,
      Users_ID: JSON.parse(userDetail).ID,
      IsActive:false,
      Teacher_Session_ID:getTeacherherSession,
      }
      // save new order
      dispatch(onAddApply(newOrder));

      showToast(true); 
    
  }

  const _selectSeans = async(item) => {
    setSeans(item)
  }


const sendMessage = async(name,day,starttime,finishtime)=> {

  let userDetail = localStorage.getItem("authUser");

  let detailUser = JSON.parse(userDetail)

  console.log("lksdlfş",detailUser)
  let newData = {"GSM" : '9' + detailUser.GsmNumber, 
  "Message" :  `Degerli ogrencimiz ${detailUser.NameSurname} Meram Gelisim Akademisine Hos Geldin! Yeni Nesil Egitim kapsaminda basvurdugun ${name} Atolyesi ${starttime} - ${finishtime} seansina kaydin basariyla tamamlanmistir.Ilk Ders ${day} gun ${starttime} Saatte.https://maps.app.goo.gl/pJWN2HeRrpTT6ZFWA`}

  let data = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns="https://webservice.asistiletisim.com.tr/SmsProxy">
    <soapenv:Header/>
    <soapenv:Body>
      <sendSms>
        <requestXml>
          <![CDATA[
            <SendSms>
              <Username>megaapi</Username>
              <Password>Mg/*-8520</Password>
              <UserCode>6817</UserCode>
              <AccountId>3887</AccountId>
              <Originator>MERAM BLD</Originator>
              <SendDate></SendDate>
              <ValidityPeriod>60</ValidityPeriod>
              <MessageText>
                Değerli öğrencimiz ${detailUser.NameSurname}
                Meram Gelişim Akademisi’ne Hoş Geldin!
                Yeni Nesil Eğitim kapsamında başvurduğun ${name} Atölyesi ${starttime} - ${finishtime} seansına kaydın başarıyla tamamlanmıştır.
                İlk Ders ${day} gün ${starttime} Saatte.
                Ders saatinden 15 dk önce Mehmet Ali Özbuğday Gençlik Merkezi’nde bulunmanız gerekmektedir.
                Konum: https://maps.app.goo.gl/pJWN2HeRrpTT6ZFWA
              </MessageText>
              <IsCheckBlackList>0</IsCheckBlackList>
              <ReceiverList>
                <Receiver>9${detailUser.GsmNumber}</Receiver>
              </ReceiverList>
            </SendSms>
          ]]>
        </requestXml>
      </sendSms>
    </soapenv:Body>
  </soapenv:Envelope>`;
  
  
  await axios.post(`https://getjob.stechomeyazilim.info:5101/sendSmsMega/send`, newData).then(async(res) => {
      
console.log("lskdf",res.data)
  })
  
  
    }

    
    const [openIframeModalVisible, setOpenIframeModalVisible] = useState({
      visible: false,
      item:null,
      item2:null
    })

    
    const closeIframeModal = () => {
      setOpenIframeModalVisible({
        ...openIframeModalVisible,
        visible: !openIframeModalVisible.visible})
    }

    React.useEffect(() => {
    
      const handleMessage = (event) => {
        if (event.data === 'success') {
          console.log('İşlem başarıyla tamamlandı');
  
          postData()
          // İşlem başarıyla tamamlandığında yapılacak işlemleri burada gerçekleştirin
        }
      }
    
      window.addEventListener('message', handleMessage);
    
      return () => {
        window.removeEventListener('message', handleMessage);
      };
   
    }, []);


    
    const postData = async() => {
      try {


          console.log("lndf",openIframeModalVisible)

      var localDate = moment().local();

      let userDetail = localStorage.getItem("authUser");
  
      const newOrder = {
        Teacher_Workshop_ID:null,
        Created_DateTime: localDate,
        Users_ID: JSON.parse(userDetail).ID,
        IsActive:false,
        Teacher_Session_ID:openIframeModalVisible.item2.ID,
        }
        // save new order
      //  dispatch(onAddApply(newOrder));

      await axios.post(`https://getjob.stechomeyazilim.info:5101/addApply/send`, newOrder).then(async(res) => {
      
        const newOrder2 = {
          Created_DateTime: localDate,
          Users_ID: JSON.parse(userDetail).ID,
          Classes_ID:res.data.value[0].ID,
          }

        
        await axios.post(`https://getjob.stechomeyazilim.info:5101/addUsersSuccessPayment/insert`, newOrder2).then(async(res) => {
      

      })

        sendMessage(openIframeModalVisible.item.Course_Schedules.Workshop.Workshop_Text,openIframeModalVisible.item.Sheduled_DayTitle,openIframeModalVisible.item.StartTime,openIframeModalVisible.item.FinishTime)
  
  
        showToast(true)


    })



      } catch (error) {
       
        console.log("error123",error)
      }

    

    }

  const openDateModal = async(item,item2,payment,price,isMeram) => {
    try {

      setLoading(true)
      console.log("ksdmklf",isMeram)
      if(isMeram == false){

            
      let userDetail = localStorage.getItem("authUser");
      
      await axios.get(`https://getjob.stechomeyazilim.info:5101/getSearchSUBDOMAINMega2/select/${JSON.parse(userDetail).ID}`).then(async(res) => {
       
      console.log("lknsdnklf",res.data)
      if(res.data.length > 0){
      if(res.data[0].Teacher_Session.Course_Schedules.Workshop_Session_ID == item.Course_Schedules.Workshop_Session_ID){

        showToast(false,'Aynı başvuru döneminde 2 atölyeye başvuramazsınız!') 

      }else{

        
        if(payment == true){
   
          navigate('/payment', { state: {
            update: true,
            item: item,
            item2 : item2,
            }
           })
      
        /*  setOpenIframeModalVisible({
            ...openIframeModalVisible,
            item,
            item2,
            visible : !openIframeModalVisible.visible
          }) */
          }else{  
          
          setDateModal(false)
          
          var localDate = moment().local();
      
          let userDetail = localStorage.getItem("authUser");
      
      
         const newOrder = {
            Teacher_Workshop_ID:null,
            Created_DateTime: localDate,
            Users_ID: JSON.parse(userDetail).ID,
            IsActive:false,
            Teacher_Session_ID:item2.ID,
            }
            // save new order
            dispatch(onAddApply(newOrder));
      
            sendMessage(item.Course_Schedules.Workshop.Workshop_Text,item.Sheduled_DayTitle,item.StartTime,item.FinishTime)
      
      
            showToast(true)
          }
      }
    }else{
      if(payment == true){
   
        navigate('/payment', { state: {
          update: true,
          item: item,
          item2 : item2,
          }
         })
    
      /*  setOpenIframeModalVisible({
          ...openIframeModalVisible,
          item,
          item2,
          visible : !openIframeModalVisible.visible
        }) */
        }else{  
        
        setDateModal(false)
        
        var localDate = moment().local();
    
        let userDetail = localStorage.getItem("authUser");
    
    
       const newOrder = {
          Teacher_Workshop_ID:null,
          Created_DateTime: localDate,
          Users_ID: JSON.parse(userDetail).ID,
          IsActive:false,
          Teacher_Session_ID:item2.ID,
          }
          // save new order
          dispatch(onAddApply(newOrder));
    
          sendMessage(item.Course_Schedules.Workshop.Workshop_Text,item.Sheduled_DayTitle,item.StartTime,item.FinishTime)
    
          setLoading(false)
          showToast(true)
    }
  }
    
    })

      }else{
        let data = JSON.stringify({
          "action": "authenticateMember",
          "params": {
            "username": "kpssporokullari",
            "password": "Kpsso4201+",
            "language": "tr"
          },
          "SP": {
            "currentCompanyId": 3429,
            "platform": "3rdParty",
            "language": "tr"
          }
        });
  
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://getjob.stechomeyazilim.info:5101/postMeram/send',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
     
        axios.request(config)
        .then((response) => {
        
          
        let data = JSON.stringify({
          "action": "getCollocutorFromNvi",
          "params": {
            "identityNumber": state.tcNumber,
            "birthdate": moment(state.birthdayDate).format('YYYY-MM-DD')
          },
          "SP": {
            "currentCompanyId": 3429,
            "platform": "3rdParty",
            "language": "tr",
            "originatorMemberId": 764,
            "proxyMemberId": null,
            "sessionId": (response.data).data.sessionId
          }
        });
        
        
        let config2 = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://getjob.stechomeyazilim.info:5101/postMeram2/send',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config2)
        .then(async(response) => {

          if(response.data.code == 0){
           if(response.data.data.address != null)
            if(response.data.data.address.townDescription == "meram")
            {
                
      let userDetail = localStorage.getItem("authUser");
      
      await axios.get(`https://getjob.stechomeyazilim.info:5101/getSearchSUBDOMAINMega2/select/${JSON.parse(userDetail).ID}`).then(async(res) => {
       
      console.log("lknsdnklf",res.data)
      if(res.data.length > 0){
      if(res.data[0].Teacher_Session.Course_Schedules.Workshop_Session_ID == item.Course_Schedules.Workshop_Session_ID){

        showToast(false,'Aynı başvuru döneminde 2 atölyeye başvuramazsınız!') 

      }else{

        
        if(payment == true){
   
          navigate('/payment', { state: {
            update: true,
            item: item,
            item2 : item2,
            }
           })
      
        /*  setOpenIframeModalVisible({
            ...openIframeModalVisible,
            item,
            item2,
            visible : !openIframeModalVisible.visible
          }) */
          }else{  
          
          setDateModal(false)
          
          var localDate = moment().local();
      
          let userDetail = localStorage.getItem("authUser");
      
      
         const newOrder = {
            Teacher_Workshop_ID:null,
            Created_DateTime: localDate,
            Users_ID: JSON.parse(userDetail).ID,
            IsActive:false,
            Teacher_Session_ID:item2.ID,
            }
            // save new order
            dispatch(onAddApply(newOrder));
      
            sendMessage(item.Course_Schedules.Workshop.Workshop_Text,item.Sheduled_DayTitle,item.StartTime,item.FinishTime)
      
      
            showToast(true)
          }
      }
    }else{
      if(payment == true){
   
        navigate('/payment', { state: {
          update: true,
          item: item,
          item2 : item2,
          }
         })
    
      /*  setOpenIframeModalVisible({
          ...openIframeModalVisible,
          item,
          item2,
          visible : !openIframeModalVisible.visible
        }) */
        }else{  
        
        setDateModal(false)
        
        var localDate = moment().local();
    
        let userDetail = localStorage.getItem("authUser");
    
    
       const newOrder = {
          Teacher_Workshop_ID:null,
          Created_DateTime: localDate,
          Users_ID: JSON.parse(userDetail).ID,
          IsActive:false,
          Teacher_Session_ID:item2.ID,
          }
          // save new order
          dispatch(onAddApply(newOrder));
    
          sendMessage(item.Course_Schedules.Workshop.Workshop_Text,item.Sheduled_DayTitle,item.StartTime,item.FinishTime)
    
          setLoading(false)

          showToast(true)
    }
  }
    
    })
             
        
            }else{
            
              setLoading(false)
              showToast(false,"Meramda Oturanlar Başvurabilmektedir",null);

              
            
            }
            else{
              setLoading(false)
              showToast(false,"Meramda Oturanlar Başvurabilmektedir",null);
            }
          }else{
            setLoading(false)
            //setFalseDesc(response.data.description)
            showToast(false,response.data.description);
          }
        })
        .catch((error) => {
          console.log(error);
        });
        
        
        })
        .catch((error) => {
          console.log(error);
        });
      }




  } catch (error) {
       
    console.log("openDateModal",error)
  }
}



  return (
    <React.Fragment>

  <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ÖĞRENCİ" breadcrumbItem="SİZE UYGUN ATÖLYE SEANSINI SEÇİN(Sadece tek atölye seçebilirsiniz)" />
          {loading && <div className="loading-screen">Yükleniyor...</div>}

    {orders.length > 0 ?
          <div className="grid-containerNew">
      {map(orders, (project, key) => 
    {

      var firstDate = moment(state.birthdayDate).format('YYYY-MM-DD'); //Create date using string-format constructor
      var secondDate = moment(new Date());
    
      var duration = moment.duration(secondDate.diff(firstDate));
      var years = parseInt(duration.asYears());

      let firstText =  null;
      let secondText =  null;

      const originalText =  project.Course_Schedules.Age_Range;

      const separator = "-";
const splittedNumbers = originalText.split(separator);

if (splittedNumbers.length === 2) {
  const firstNumber = parseInt(splittedNumbers[0]); // "8"
  const secondNumber = parseInt(splittedNumbers[1]); // "11"
  
  firstText = firstNumber
  secondText= secondNumber

} else {

}

let userDetail = localStorage.getItem("authUser");
let userDetail2 = JSON.parse(userDetail)
//let filterData =  project.Course_Schedules.Teacher_Session.length > 0 && project.Course_Schedules.Teacher_Session[0].Classes.filter((x)=> x.Users_ID == userDetail2.ID).length > 0 ? project.Course_Schedules.Teacher_Session[0].Classes.filter((x)=> x.Teacher_Session_ID ==project.Course_Schedules.Teacher_Session[0].ID && x.Users_ID == userDetail2.ID) : []

let count = project.Course_Schedules.Teacher_Session.length > 0 && project.Course_Schedules.Teacher_Session[0].Classes.length > 0 ? project.Course_Schedules.Number_OfQuotas - project.Course_Schedules.Teacher_Session[0].Classes.length : null


    return(
      count == 0 ? null :
     
       firstText <= parseInt(years) && (secondText >= parseInt(years)) && project.Course_Schedules.Teacher_Session.length > 0 ?
     <button
     className="border-0 grid-itemNew"
         onClick={()=> project.Course_Schedules.Teacher_Session.length > 0 && (project.Course_Schedules.Teacher_Session[0].Classes_Extra.length > 0  ||  project.Course_Schedules.Teacher_Session[0].Classes.filter((x)=> x.Users_ID == userDetail2.ID).length > 0)? showToast(false,'Önceden Başvuru Yaptınız!') : openDateModal(project,project.Course_Schedules.Teacher_Session[0],project.Course_Schedules.IsMePayment,project.Course_Schedules.Price,project.Course_Schedules.Workshop_Sessions.IsMeram)}
         >
        <Col key={key}>
          <Card>
            <CardBody>
            
           

                <div className=" border-bottom text-center">

                  <h5 className="font-size-25 text-danger text-center">
                      {project.Course_Schedules.Workshop_Sessions.Workshop_Sessions_Text}
                  </h5>
                  </div>


              <div className={"text-center mt-2  bg-warning rounded-md"}>

                {/* <h3 className="text-white font-bold font-size-30 center">
                      {project.Course_Schedules.Workshop_Sessions.SUBDOMAIN.SUBDOMAIN_Title}
                  </h3> */} 
              
                <div className="border-bottom  p-2">
               { project.Course_Schedules.Workshop != null ?  <h5 className="text-white font-size-25">
                       {project.Course_Schedules.Workshop.Workshop_Text}
                  </h5> : null}
                  </div>           
                </div>

                 

                <div className=" bg-danger text-center align-items-center justify-center">

                  <h4 className="text-white font-size-25 p-2 text-center">
                     {project.Sheduled_DayTitle}
                  </h4>
                  
                  <div className="d-flex bg-danger text-center align-items-center justify-center  justify-content-center">

                  <h5 className="text-white font-size-25 text-center">
                     {project.StartTime} -
                  </h5>

                  <h5 className="text-white font-size-25 text-center">
                     {project.FinishTime}
                  </h5>

                  </div>

                  </div>
                

                {project.Course_Schedules.Teacher_Session.length > 0 && (project.Course_Schedules.Teacher_Session[0].Classes_Extra.length > 0  ||  project.Course_Schedules.Teacher_Session[0].Classes.filter((x)=> x.Users_ID == userDetail2.ID).length > 0)? 
                 <div className="mt-2 bg-success text-center align-items-center justify-center">
                 <h5 className="text-white font-size-25 p-2 border-bottom">
                   ÖNCEDEN BAŞVURU YAPTINIZ
                 </h5>

                {project.Course_Schedules.Teacher_Session.length > 0 ?
                 project.Course_Schedules.Teacher_Session[0].Classes.filter((x)=> x.Users_ID == userDetail2.ID ).map((item, index) => (
                  <div key={index} className="p-2">

                  <h5 key={index} className="text-white font-size-25 ">
                  Tarih : {moment(item.Created_DateTime).format('DD/MM/YYYY')}
                 </h5> 
                 </div>)) : null}


                 {project.Course_Schedules.Teacher_Session.length > 0 ?
                project.Course_Schedules.Teacher_Session[0].Classes_Extra.map((item, index) => 
                {
                  
                  return(
     
                  <h6 key={index} className="text-white font-size-25">
                  Tarih : {moment(item.Created_DateTime).format('DD/MM/YYYY')}
                 </h6> )}) : null}
                
                
                 </div>:


                <div className="bg-danger text-center align-items-center justify-center mt-2">
                  <h4 className="text-white font-size-25 p-2">
                     BAŞVURU YAP
                  </h4>
                 
                  </div> 
                  }

            </CardBody>
       
          </Card>
        </Col>
         </button> : 
         key == 0 ? null : null
      )})}
      </div> : 
      <div className="grid-containerNew">

<h5 className="font-size-25 text-danger text-center">
                      Size uygun atöyle seansı bulunmamaktadır!
                  </h5>

        </div>}

    
   
      <Modal
      isOpen={dateModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => setDateModal(false)}>
      <div className="modal-content">
      {getSeans == null ?  
      <ModalHeader toggle={() => setDateModal(false)}>Seansa Katılmak İstiyorsun?</ModalHeader> :
      <ModalHeader toggle={() => setDateModal(false)}>{getSeans.Sheduled_DayTitle} Günün Seansını Seçtiniz</ModalHeader>}
        <ModalBody>
          {dateArray.length > 0 ?
                dateArray.map((item, index) => (
                  <button onClick={()=> _selectSeans(item)} key={index} className={`${getSeans == null ? 'bg-white' : item.ID == getSeans.ID ?  'bg-danger' : null}`} >

                  <h6 key={index} className={`${getSeans == null ? 'text-success' :  item.ID == getSeans.ID ? 'text-white text-center' : null}`}>
                  Gün : {item.Sheduled_DayTitle}
                 </h6> 

                 <div className="d-flex">
 
                 <h6 key={index} className={`${getSeans == null ? 'text-danger' :  item.ID == getSeans.ID ? 'text-white text-center' : null}`}>
                  Başlangıç : {item.StartTime} -
                 </h6> 

                 <h6 key={index} className={`${getSeans == null ? 'text-success' :  item.ID == getSeans.ID ? 'text-white text-center' : null}`}>
                   Bitiş : {item.FinishTime}
                 </h6> 

                 </div>
                 </button>)) : null}
        </ModalBody>
        <ModalFooter>

          <Button type="button" color="primary" onClick={() => setDateModal(false)}>
            Kapat
          </Button>

          <Button type="button" color="secondary" onClick={() => _setDateModalFunc()}>
            Katıl 
          </Button>
          
        </ModalFooter>
      </div>
    </Modal>



        </div>
      </div>


    
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;